@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';

.Root {
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}

.Button {
  margin-right: 15px !important;
}


.Paper {
  padding: 24px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
}

.Input {
  width: 40% !important;
  margin-bottom: 15px !important;
  grid-column: 1 / 4;
}

.Actions {
  display: flex;
  column-gap: 20px;
  margin-top: 30px;
  margin-bottom: 100px;
  justify-content: initial !important;
  width: initial !important;

  &Btn {
    background-color: #60bc12 !important;
    color: white !important;
    height: 36px;
    margin-top: 16px !important;

    &:hover {
      background-color: #60bc12 !important;
      color: white !important;
      opacity: 0.7;
    }
  }
}