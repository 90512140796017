// Shared
@import 'styles/breakpoints.scss';

.Root {
  flex-shrink: 0;
  
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
