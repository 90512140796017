@import './breakpoints.scss';

// Theme
// .MuiTouchRipple-ripple {
//   display: none !important;
// }

// Screen node display switcher
br {
  &[data-sm],
  &[data-md],
  &[data-lg] {
    display: none;
  }
  @media only screen and (min-width: $sm) {
    &[data-sm] {
      display: inline;
    }
  }
  @media only screen and (min-width: $md) {
    &[data-sm] {
      display: none;
    }
    &[data-md] {
      display: inline;
    }
  }
  @media only screen and (min-width: $lg) {
    &[data-sm],
    &[data-md] {
      display: none;
    }
    &[data-lg] {
      display: inline;
    }
  }
}
