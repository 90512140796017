@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Paper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 24px;
  margin-top: 30px;
  column-gap: 10px;

  &Target {
    grid-column: 1 / 4;
  }
}

.ActionTitle {
  display: flex;
  grid-column: 1 / 5;
  margin-top: 20px;
  align-items: center;
  column-gap: 20px;
}

.ActionRemove {
  height: 30px;
  background-color: #FF0040 !important;
  color: white !important;

  &:hover {
    background-color: #FF0040 !important;
    color: white !important;
    opacity: 0.7;
  }
}

.ActionAdd {
  margin-top: 12px!important;
  background-color: #60bc12!important;
  color: white!important;
  grid-column: 1 / 5;
  width: 30%;

  &:hover {
    background-color: #60bc12!important;
    color: white!important;
    opacity: 0.7;
  }
}

.TargetPlanField {
  grid-column: 1 / 5;
  width: 30%;
}

.Select {
  margin-top: 32px!important;
}

.Checkbox {
  margin-top: 32px!important;
}

.Comment {
  grid-column: 1 / 5;
  width: 50%;
}

.Buttons {
  display: flex;
  align-items: center;
}

.Button {
  margin-right: 10px !important;

  &:last-child {
    margin-right: 0;
  }
}
