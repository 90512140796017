@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Root {
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}

.Paper {
  padding: 24px;
  margin-bottom: 30px;
}

.DateCompletion {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
}

.SectionOne {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;

  & > div {
    width: 30%;
  }
}

.Subtitle {
  margin-top: 40px!important;
}
