@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';

.Root {
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}

.IconButton {
  
}

.Button {
  margin-right: 15px !important;
}