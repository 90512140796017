.Actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: $md) {
    width: 50%;
  }

  @media only screen and (min-width: $lg) {
  }
}
