@import 'styles/breakpoints.scss';

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}
