// Shared
@import 'styles/breakpoints.scss';

.Root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: #fff;
  height: 39px;
  border-radius: 4px;
  
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}

.Input {
  width: 100%;
  margin-left: 10px;
}