@import 'styles/breakpoints.scss';

.Root {

  &Is {
    &Required {
      &::after {
        content: '*';
        position: absolute;
        right: 0;
        bottom: 0;
        color: #FF0040;
      }
    }
  }

  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
