@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Paper {
  padding: 24px;
  margin-top: 30px;
}

.InputHalfWith {
  width: 50%;
}

.Row {
  display: flex;
  column-gap: 30px;
}

.Btn {
  width: 100px;
  height: 36px;
  margin-top: 12px!important;
  background-color: #60bc12!important;
  color: white!important;

  &:hover {
    background-color: #60bc12!important;
    color: white!important;
    opacity: 0.7;
  }
}
