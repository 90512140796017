@import 'styles/breakpoints.scss';

.Root {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.Container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.Item {
  flex: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  max-width: initial!important;
  span {
    font-size: 14px!important;
  }
}

