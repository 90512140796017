@import 'styles/breakpoints.scss';

.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 10em;

  &:hover, &:active {
    cursor: pointer;
    background-color: transparent !important;
  }

  &[disabled] {
    pointer-events: none;
  }
}
