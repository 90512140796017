@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Paper {
  padding: 24px;
}

.H5 {
  margin-top: 30px!important;
  text-align: center;
}

.TextCenter {
  text-align: center;
}

.SubmitBtn {
  margin-top: 30px!important;
  margin-bottom: 120px!important;
}
