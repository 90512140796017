@import 'styles/breakpoints.scss';

.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover, &:active {
    cursor: pointer;
    background-color: transparent !important;
  }

  &[disabled] {
    pointer-events: none;
  }

  &Is {
    &FullWidth {
      width: 100%;
    }

    &Loading {
      pointer-events: none;
    }
  }

  // &Variant {
  //   &Contained {
  //   }

  //   &Text {
  //   }

  //   &Outlined {
  //   }
  // }
}

.CircularProgress {
  position: absolute;
}
