// Project fonts
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

@font-face {
  font-family: 'Gilroy';
  src:
    url('/assets/fonts/Gilroy/Gilroy-Thin.eot') format('embedded-opentype'),
    url('/assets/fonts/Gilroy/Gilroy-Thin.ttf') format('truetype'),
    url('/assets/fonts/Gilroy/Gilroy-Thin.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src:
    url('/assets/fonts/Gilroy/Gilroy-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype'),
    url('/assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src:
    url('/assets/fonts/Gilroy/Gilroy-SemiBold.eot') format('embedded-opentype'),
    url('/assets/fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src:
    url('/assets/fonts/Gilroy/Gilroy-Bold.eot') format('embedded-opentype'),
    url('/assets/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype'),
    url('/assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
