@import 'styles/breakpoints.scss';

.Additional {

  @media only screen and (min-width: $md) {
    width: 50%;
    text-align: right;
    padding: 0 0 0 16px;
  }

  @media only screen and (min-width: $lg) {
  }
}
