@import 'styles/breakpoints.scss';

.Button {
  margin: 16px 0 !important;
  
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}
