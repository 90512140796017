@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Root {
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}

.Paper {
  padding: 24px;
  margin: 24px 0 24px 0;
}

.H5 {
  margin-top: 30px!important;
  text-align: center;
}

.TextCenter {
  text-align: center;
}

.AboutChild {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}

.Touchstones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;

  &Title {
    grid-column: 1 / 3;
  }

  &Fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    grid-column: 1 / 3;
  }

  &Input {
    margin-top: 0 !important;
  }
}
