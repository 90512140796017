@import 'styles/breakpoints.scss';

.Root {
  width: 100%;
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.Container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (min-width: $lg) {
    justify-content: space-between;
  }
}

.Content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
}

.Toolbar {
  justify-content: space-between;
  .Menu {
    @media only screen and (min-width: $lg) {
      display: none;
    }
  }
}

.UserBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  & span {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}
