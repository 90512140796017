// Shared
@import 'styles/breakpoints.scss';

.Root {
  
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}

.Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
