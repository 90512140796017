@import 'styles/breakpoints.scss';

// Shared
@import  'styles/components/Root.scss';
@import  'styles/components/Wrapper.scss';
@import  'styles/components/Title.scss';
@import  'styles/components/Actions.scss';
@import  'styles/components/Form.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Input.scss';

.Actions {
  display: flex;
  column-gap: 20px;
  margin-top: 30px;
  margin-bottom: 100px;
  justify-content: initial!important;
  width: initial!important;

  &Btn {
    background-color: #60bc12!important;
    color: white!important;
    height: 36px;
    margin-top: 16px!important;

    &:hover {
      background-color: #60bc12!important;
      color: white!important;
      opacity: 0.7;
    }
  }

  &AddOtherSideBtn {
    grid-column: 1 / 3;
    background-color: #60bc12!important;
    color: white!important;
    height: 36px;
    margin-top: 16px!important;
    width: 30%;

    &:hover {
      background-color: #60bc12!important;
      color: white!important;
      opacity: 0.7;
    }
  }
}

.ChildContainer {
  display: flex;
  column-gap: 20px;
}

.Paper {
  padding: 24px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 130px;
}

.Agreements {
  padding: 24px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1 / 3;
  row-gap: 10px;

  &Container {
    display: flex;
    align-items: center;
    column-gap: 10px;

    & > a {
      text-decoration: none;
      color: #333;
    }
  }
}
