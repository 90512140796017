@import 'styles/breakpoints.scss';

.Wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (min-width: $md) {
    align-items: flex-start;
    justify-content: space-between;
  }

  @media only screen and (min-width: $lg) {
  }
}
