@import 'styles/breakpoints.scss';

.Root {
  position: relative;
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $lg) {
  }
}

.Container {
  display: flex !important;
  margin: 0 auto;
  width: 100%;
  flex: 1 auto;
}

.Content {
  position: relative;
  background-color: #F7F7F7;
  flex: 1 auto;
  padding: 64px 0;

  @media only screen and (min-width: $md) {
    padding: 64px 0 64px 32px;
  }

  @media only screen and (min-width: $lg) {
    padding: 64px 0 64px 272px;
  }
}
