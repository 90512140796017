// Shared
@import 'styles/breakpoints.scss';

@import  'styles/components/Form.scss';
@import  'styles/components/Input.scss';
@import  'styles/components/Checkbox.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Link.scss';
@import  'styles/components/Paragraph.scss';

.Link {
  display: inline;
}

.Actions {
  display: flex;
  column-gap: 30px;
}
