@import 'styles/breakpoints.scss';

.Title {
  width: 100% !important;

  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}
