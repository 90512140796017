@import 'styles/breakpoints.scss';

.Root {
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}

.RootIsRequired:after {
  content: "*";
  position: absolute;
  right: 0;
  bottom: 0;
  color: #FF0040;
}

.Select {
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
