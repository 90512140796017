@import 'styles/breakpoints.scss';

.Root {
  flex-shrink: 0 !important;

  .Toolbar {
    justify-content: flex-end;
    @media only screen and (min-width: $lg) {
      display: none;
    }
  }

  .Container {
    overflow: auto;
    @media only screen and (min-width: $sm) {
    }
    @media only screen and (min-width: $lg) {
      padding: 0;
    }
    .List {

      &Item {
        padding-left: 16px;
        padding-right: 16px;
        
        &Icon, &Text {
          color: #BCBCBC !important;
        }
      }
    }
  }
}

.Paper {
  
  width: 100%;
  z-index: 100 !important;
  @media only screen and (min-width: $md) {
    width: 50%;
  }
  @media only screen and (min-width: $lg) {
    width: 240px !important;
    position: fixed !important;
    padding: 64px 0 !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    top: auto !important;
    left: auto !important;
  }
}




