// Shared
@import 'styles/breakpoints.scss';

.Root {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 !important;

  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
