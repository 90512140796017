@import 'styles/breakpoints.scss';

.Input {
  margin: 16px 0 !important;

  &_w100 {
    width: 100%;
  }
  
  @media only screen and (min-width: $md) {
  }
}
