@import 'styles/breakpoints.scss';

.Root {
  @media only screen and (min-width: $md) {
  }

  @media only screen and (min-width: $lg) {
  }
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
}

