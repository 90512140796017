@import 'styles/breakpoints.scss';

.Root {
  font-feature-settings: 'liga' off;

  &Variant {
    &Caption {
      font-family: 'Gilroy-Thin', sans-serif !important;
    }

    &H1 {
      font-family: 'Gilroy-Bold', sans-serif !important;
      font-size: 3rem !important;
      margin: 1rem 0 !important;
    }

    &H2 {
      font-family: 'Gilroy-Bold', sans-serif !important;
      font-size: 2.5rem !important;
      margin: 1rem 0 !important;
    }

    &H3 {
      font-family: 'Gilroy-Bold', sans-serif !important;
      font-size: 2rem !important;
      margin: 1rem 0 !important;
    }

    &H4 {
      font-family: 'Gilroy-SemiBold', sans-serif !important;
      font-size: 1.75rem !important;
      margin: 0.75rem 0 !important;
    }

    &H5 {
      font-family: 'Gilroy-SemiBold', sans-serif !important;
      font-size: 1.5rem !important;
      margin: 0.75rem 0 !important;
    }

    &H6 {
      font-family: 'Gilroy-SemiBold', sans-serif !important;
      font-size: 1.25rem !important;
      margin: 0.75rem 0 !important;
    }

    &P {
      font-family: 'Gilroy-Regular', sans-serif !important;
      margin: 0.5rem 0 !important;
    }

    &Subtitle {
      font-family: 'Gilroy-Regular', sans-serif !important;
      margin: 0.75rem 0 !important;
    }
  }
}
