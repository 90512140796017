// Shared
@import 'styles/breakpoints.scss';

@import  'styles/components/Form.scss';
@import  'styles/components/Input.scss';
@import  'styles/components/Table.scss';
@import  'styles/components/Checkbox.scss';
@import  'styles/components/Button.scss';
@import  'styles/components/Link.scss';
@import  'styles/components/Paragraph.scss';

.FormSegment, .Paper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.Paper {
  &Container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
  }
}

.QuestionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  margin-bottom: 24px;

  &Select {
    margin-top: -20px!important;
    width: 40%;
  }

  &Input {
    width: 100%;
  }
}

.Paper {
  padding: 16px 32px !important;
  margin: 16px 0;
}

.Subtitle, .H5 {
  width: 100% !important;
}

.Title {
  margin-top: 16px!important;
  margin-bottom: 0!important;
  width: 100% !important;
}

.SubtitleSmall {
  font-size: 12px;
}

.SubtitleMarginTop {
  margin-top: 30px!important;
}

.FormSelect {
  margin: 0 !important;
}

.FormInput {
  margin: 16px 0 !important;
}

.Input {
  width: 45%;
}

.Link {
  display: inline;
}

.Table {
  margin-bottom: 30px;
}

.CheckBox {
  margin-left: initial!important;
  margin-right: initial!important;
}
