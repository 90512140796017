// Shared
@import 'styles/breakpoints.scss';

.Root {
  cursor: pointer;
  color: #60bc12;
  text-decoration: none !important;
  font-weight: 500 !important;
  
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
